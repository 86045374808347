import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from '../components';
import Section from '../components/Section';
import SectionTitle from '../components/SectionTitle';
import SliceZone from '../components/SliceZone';


const TitleImage = styled.div`
  width: 100%;
  padding-top: 36.25%;
  
  background-image: url(${props => props.url});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; 
`;

const Post = ({ data: { post } }) => (
  <Layout>
    <TitleImage url={post.data.title_image.localFile.childImageSharp.fluid.src}/>
    <Section>
      <SectionTitle as='h1' centered>{post.data.title.text}</SectionTitle>
      <SliceZone allSlices={post.data.body} />
    </Section>
  </Layout>
);

export default Post;

Post.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.object.isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    post: prismicPost(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title {
          text
        }
        title_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        description
        date(formatString: "DD.MM.YYYY")
        categories {
          category {
            document {
              data {
                name
              }
            }
          }
        }
        body {
          ... on PrismicPostBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPostBodyImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPostBodyAmazonAffiliateLink {
            id
            slice_type
            primary {
              product_name {
                text
                raw {
                  text
                }
              }
              product_image {
                url
                raw {
                  url
                  link_type
                }
              }
              amazon_affiliate_link {
                url
                raw {
                  url
                  link_type
                }
              }
            }
          }
        }
      }
    }
  }
`;
